/* 
   Filename: App.css
   Author: Samuel Ardis
   Date: 12/10/2021
   Portfolio 2.0
 */

/* Notes:
  -
*/

/* media / screen sizing */
  /* --iphoneSE: 325px;
  --phone: 400px;
  --smallTablet: 520px;
  --mediumTablet: 700px;
  --tablet: 825px;
  --smallScreen: 1040px;
  --bigScreen: 1150px;
  --hugeScreen: 1270px; */

/* reset style */
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

a, article, body, div, figcaption, figure, footer, header, h1, 
h2, h3, img, li, nav, p, section, ol, ul, fieldset, form, legend, 
input, label, textarea, option, select, dl, dt, dd, table, thead, 
tbody, th, td, span, video, audio {
    border: 0;
    padding: 0;
    margin: 0;
}
ul {
  list-style: none;
}
input,
textarea,
select,
button {
  color: inherit; 
  font: inherit; 
  letter-spacing: inherit; 
}
button {
  border-radius: 0; 
  padding: 0.75em 1em;
  background-color: transparent;
}
embed,
iframe,
img,
object,
video {
  display: block;
  max-width: 100%;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
[hidden] {
  display: none;
}
[hidden] {
  display: none !important;
}
a {
  text-decoration: none;
  color: #000;
}

/* variable styles */
:root {
  --clr-background: #F4F1ED; /* Neutral */
  --clr-background-2: #E6DDC4; /* Neutral */
  --clr-font: #4E3629; /* Copper */
  --clr-primary: #7D5A50; /* Copper */
  --clr-secondary: #D7BFAE; /* Neutral/Natural */
  --clr-glow: #A67B5B; /* Copper/Natural */
  --clr-pop: #C3A995; /* Neutral/Natural */
  /* sizing */
  --font-main: 1.2em;
  --font-large: 1.5em;
  --font-icon: 3.5em;
  /* effects */
  --font-title:'Roboto Mono', monospace;
  --font-body: 'Space Mono', monospace;
  --transition: all 0.3s ease;
}

/* dark mode styles */
.dark-mode {
  --clr-background: #2D2926; /* Dark Neutral */
  --clr-background-2: #A67B5B; /* Copper/Natural */
  --clr-primary: #D7BFAE; /* Neutral/Natural */
  --clr-secondary: #4E3629; /* Copper */
  --clr-font: #F4F1ED; /* Neutral */
}
.dark-mode__container {
  position: absolute;
  display: flex;
  margin: 0 auto;
  top: 51px;
  right: 40px;
}
.dark-mode__container .light__symbol{
  margin: 4px 3.5px 0 3px;
  color: var(--clr-pop);
}
.dark-mode__container .dark__symbol{
  margin: 5.5px 3.5px 0 3.5px;
  color: var(--clr-secondary);
}
.dark-mode__toggle {
  margin: 2px;
  background: transparent;
  border-radius: 50px;
  border: 3px solid var(--clr-background);
  height: 27px;
  position: relative;
  width: 50px;
}
.toggle {
  background: var(--clr-background);
  border-radius: 100px;
  height: 17.5px;
  width: 22px;
  left: 2.5px;
  margin-top: 1.4px;
  position: absolute;
  transition: var(--transition);
}
.toggle:hover {
  cursor: pointer;
}
.toggled {
  left: 19px;
}

/* dark mode responsive styles */
@media(max-width: 820px) {
  .dark-mode__container {
    top: 259px;
    right: 30px;
  }
}

/* main styles */
.App {
  background-color: var(--clr-background);
  margin: 0 auto;
}
.content {
  font-family: var(--font-body);
  min-height: 75vh;
  text-align: center;
  margin: 0 auto 20px auto;
}
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: var(--clr-glow);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary);
  border-radius: 20px; 
  border: 3px solid var(--clr-glow); 
}
img {
  display: block;
  margin: 20px auto;
}
h1, h2, h3 {
  font-family: var(--font-title);
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--clr-font);
}

/* navigation */
.nav {
  font-family: var(--font-title);
  text-transform: uppercase;
  letter-spacing: 5px;
  background-color: var(--clr-primary);
  width: 100%;
  padding: 40px 0 80px 0;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.nav h3 {
  position: absolute;
  color: var(--clr-pop);
  line-height: 30px;
  letter-spacing: 3px;
  padding: 10px;
  font-size: var(--font-large);
  left: 2%;
}
.nav ul {
  position: absolute;
  left: 37%;
  display: flex;
  justify-content: space-around;
}
.nav ul li {
  background-color: var(--clr-primary);
  font-weight: bold;
  margin: 0 10px;
  padding: 15px;
  border-bottom: 5px solid transparent;
  cursor: pointer;
  font-size: var(--font-main);
  transition: var(--transition);
}
.nav ul li:hover {
  border-bottom: 5px solid var(--clr-pop);
}
.nav ul a {
  color: var(--clr-glow);
}

/* navigation responsive styles */
@media (max-width: 1200px) {
  .nav ul {
    left: 25%;
  }
}

@media (max-width: 820px) {
  .nav {
    padding: 160px 0;
  }
  .nav h3 {
    top: 250px;
    left: 30px;
  }
  .nav ul{
    flex-direction: column;
    width: 90%;
    padding: 0;
    left: 5%;
    margin: 0 auto;
    top: 15px;
  }
  .nav ul li {
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    margin: 6px 0;
    border-radius: 5px;
    background-color: var(--clr-background);
  }
  .nav ul a {
    color: var(--clr-font);
  }
}

/* social links */
.social-background {
  background-color:var(--clr-glow);
  margin: 0;
  padding: 0;
}
.social-links {
  background-color:var(--clr-glow);
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.social-links a {
  color: var(--clr-primary);
  margin: 20px auto 10px auto;
  font-size: var(--font-icon);
}
.social-links a:hover {
  color: var(--clr-pop);
}

/* social links responsive styles */
@media (max-width: 1040px) {
  .social-links {
    width: 70%;
  }
}
@media (max-width: 820px) {
  .social-links {
    width: 95%;
  }
}

/* project styles */
.projects-name {
  margin-bottom: 25px;
}
.projects {
  display: flex;
  flex-wrap: wrap;
  padding: 2% 0 2% 0;
  justify-content: space-evenly;
  width: 90%;
  border-radius: 5px;
  margin: 60px auto 70px auto;
}
.project {
  background-color: var(--clr-primary);
  color: #fff;
  width: 320px;
  height: 480px;
  margin: 30px;
  padding: 20px 30px;
  position: relative;
  border-radius: 10px;
}
.project-img img {
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 40px;
  width: 100%;
}
.project-name {
  position: absolute;
  background-color: var(--clr-primary);
  border-radius: 8px 8px 0 0;
  left: 0;
  height: 40px;
  width: 100%;
  top: 0;
}
.project-name h3 {
  color: var(--clr-glow);
  margin-top: 8px;
}
.project-description {
  color: #fff;
  overflow-y: auto;
  position: absolute;
  text-align: left;
  font-size: .95rem;
  right: 15px;
  border-radius: 5px;
  padding: 5px;
  width: 90%;
  height: 220px;
  bottom: 65px;
}
.project-description p:nth-child(2){
  margin-top: 10px;
}
.project-description p span {
  text-transform: lowercase;
}
.project-description p:last-child {
  margin-top: 10px;
}
.project-links {
  font-family: var(--font-title);
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 90%;
  right: 15px;
  bottom: 25px;
}
.project-links a {
  background-color: var(--clr-glow);
  color: var(--clr-primary);
  letter-spacing: 3px;
  font-weight: bold;
  width: 90%;
  margin: -10px 10px;
  border-radius: 5px;
  padding: 8px;
}
.project-links a:hover {
  transition: var(--transition);
  background-color: var(--clr-pop);
  color: var(--clr-primary);
}

/* about page styles */
.about-content {
  align-items: center;
}
.about-content h3 {
  color: var(--clr-glow);
  margin: 10px auto;
}
.about-name {
  margin: 30px auto;
}
.about-box {
  color: var(--clr-font);
  text-align: left;
  width: 80%;
  margin: 0 auto;
  padding: 10px;
}
.about-box p span {
  font-style: italic;
}
.about-box p {
  line-height: 1.3em;
  margin-top: 10px;
}
.about-box h3 {
  background-color: var(--clr-primary);
  padding: 5px;
}
.about-title h2 {
  margin-bottom: 10px;
}
.about-img-theo {
  position: absolute;
  bottom: 2px;
  right: 25px;
  margin: 0;
}
.btn-modal {
  background-color: var(--clr-font);
  color: var(--clr-background);
  border: none;
  margin-top: 5px;
  border-radius: 5px;
  padding: 2px 8px;
  cursor: pointer;
}
.btn-modal:hover {
  background-color: var(--clr-pop);
  color: var(--clr-primary);
  transition: var(--transition);
}

/* Modal */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  position: relative;
  z-index: 99;
  width: 100%;
  max-width: 12rem;
  max-height: 100%;
  margin: 0 auto;
}
.modal-close {
  position: absolute;
  font-size: 2.25rem;
  top: -250px;
  right: -40px;
  border: 0;
  -webkit-appearance: none;
  background: none;
  color: var(--clr-glow);
  cursor: pointer;
}
.modal-close:hover {
  color: var(--clr-pop);
}
.modal-body {
  padding: 1.25rem 1.5rem;
  border-radius: 4px;
  background-color: transparent;
}

/* contact page */
.contact-container h2 {
  margin: 20px auto;
}
form {
  width: 400px;
  margin: 35px auto 100px auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bold;
  color: var(--clr-font);
  background-color: var(--clr-primary);
  border-radius: 10px;
  padding: 20px 10px;
}
form label {
  color: var(--clr-glow);
}
form input, textarea {
  color: var(--clr-primary);
  background-color: var(--clr-glow);
  margin: 10px 0;
  padding: 5px;
  font-weight: normal;
  border-radius: 5px;
}
form textarea {
  padding: 10px;
  width: 100%;
  height: 125px;
}
form input[type=submit] {
  font-family: var(--font-title);
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 10px;
  font-weight: bold;
  color: var(--clr-font);
  background-color: var(--clr-background);
  cursor: pointer;
  transition: var(--transition);
  border-radius: 10px;
}
form input[type=submit]:hover {
  background-color: var(--clr-pop);
  color: var(--clr-secondary);
}

/* contact page responsive styles */
@media (max-width: 820px) {
  form {
    width: 85%;
    margin: 35px auto;
  }
}

/* footer */
footer {
  font-family: var(--font-body);
  background-color: #000;
  color: #E8E9EB;
  width: 100%;
  padding: 50px 0;
  bottom: 0;
  display: flex;
}
footer div {
  margin: 0 auto;
}
footer a {
  color: var(--clr-pop);
  margin-left: 10px;
}
